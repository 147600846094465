import { DetailsListColumn, InfiniteDetailsList } from "@greco/components";
import { getTheme, Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as React from "react";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RealEstate } from "../../api/obj-api-model/RealEstate";
import { search, SortCriteria } from "../../api/ObjectsApi";
import { AppContext } from "../../contexts/appContext";
import { columns } from "./columns";

type ObjectsState = { items: RealEstate[]; total: number; content?: boolean };
type Props = {
  clientID?: number;
  sort: SortCriteria;
  setSort: (sort: SortCriteria) => void;
};

export const ObjectsTable = ({ clientID, sort, setSort }: Props) => {
  const [objects, setObjects] = useState<ObjectsState>();
  const { searchQuery } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = getTheme();
  const noContentText: CSSProperties = {
    textAlign: "center",
    fontSize: "1.4rem",
    fontWeight: 600,
    color: theme.palette.neutralSecondary,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const loadObjects = async (skip: number, top: number) => {
    if (!clientID) return;
    try {
      const res = await search(clientID, skip, top, sort, searchQuery);
      setObjects({
        items:
          skip && objects ? objects.items.concat(res.objects) : res.objects,
        total: res.total,
        content: !!res.total,
      });
    } catch (err) {
      setObjects({ items: [], total: 0 });
    }
  };

  const searchPerformed = () => {
    setObjects(undefined);
    loadObjects(0, 30);
  };

  useEffect(searchPerformed, [sort, clientID, searchQuery]);

  if (objects && objects.content) {
    return (
      <InfiniteDetailsList
        id="offers"
        columns={columns.map((column: DetailsListColumn) => {
          return {
            ...column,
            label: t(column.label),
          };
        })}
        rows={objects.items.map((object: RealEstate) => ({
          ...object,
          street: `${object.street}/${object.houseNumber}`,
        }))}
        columnWidth={({ index }) =>
          columns[index].key === "name"
            ? window.innerWidth - 1000
            : columns[index].width
        }
        height={window.innerHeight - 50 - 44}
        width={window.innerWidth}
        loadMoreRows={({ startIndex, stopIndex }) =>
          loadObjects(startIndex, stopIndex - startIndex)
        }
        minimumBatchSize={31}
        rowCount={objects.total}
        threshold={15}
        sort={sort}
        onClickHeaderCell={({ column }) => {
          if (!column.isSortable) return;
          const isSorted = sort.key === column.key;
          if (isSorted) {
            if (sort.dir === "asc") {
              setSort({
                key: column.key,
                dir: "desc",
              });
            } else {
              setSort({
                key: "",
                dir: "desc",
              });
            }
          } else {
            setSort({
              key: column.key,
              dir: "asc",
            });
          }
        }}
      />
    );
  }

  if (objects)
    return (
      <div style={noContentText}>
        <span>{t("crm.search.noRecords.text")}</span>
      </div>
    );

  return (
    <Spinner
      size={SpinnerSize.large}
      styles={{
        root: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    />
  );
};
