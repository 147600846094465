import { IAuthProviderConfiguration } from "@greco/services/dist/lib/AuthProvider/AuthMSAL";

export const MSGraphParams: IAuthProviderConfiguration = {
  appId: process.env.REACT_APP_CLIENT_ID!,
  redirectUri: window.location.origin,
  scopes: ["User.Read.All", "Files.ReadWrite.All"],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};

export const AzureGraphParams = {
  appId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: ["https://graph.windows.net/User.Read"],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};
