import React from "react";
import {
  ExcelExportColumn,
  ExcelExport,
} from "@progress/kendo-react-excel-export";
import { useTranslation } from "react-i18next";
import { columns } from "./columns";

export const ExportExcel = React.forwardRef<ExcelExport, {}>((_props, ref) => {
  const { t } = useTranslation();

  return (
    <ExcelExport ref={ref} fileName="RealEstatesList">
      {columns.map((col) => (
        <ExcelExportColumn key={col.key} field={col.key} title={t(col.label)} />
      ))}
    </ExcelExport>
  );
});
