import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CommandBar, SearchBox, getTheme, Stack } from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";
import { search, SortCriteria, uploadList } from "../../api/ObjectsApi";
import { AppContext } from "../../contexts/appContext";
import { parseErrorFromService } from "../../utils/ErrorHandling";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ExportExcel } from "./ExportExcel";
import { RealEstate } from "../../api/obj-api-model/RealEstate";
import { columns } from "./columns";

export interface IActionBarProps {
  searchQuery?: string;
  setSearch: (query?: string) => void;
  performSearch: () => void;
  sort: SortCriteria;
  clientID?: number;
  clientName?: string;
  openClientModal: () => void;
}

interface RealEstateForExcel extends RealEstate {
  isRentedForExcel: string;
}

export const ActionBar = ({
  searchQuery,
  setSearch,
  performSearch,
  sort,
  clientID,
  clientName,
  openClientModal,
}: IActionBarProps) => {
  const theme = getTheme();
  const { t } = useTranslation();
  const { toast, searchQuery: activeSearch } = useContext(AppContext);
  const inputRef = useRef<any>();
  const onChange = (e: any) => {
    const uploads = [...e.target.files];
    e.target.value = null;
    clientID &&
      uploadList(clientID, uploads[0]).then(
        () => performSearch(),
        (error) =>
          toast.notify({
            type: "error",
            title: t("rec.error.title"),
            content: parseErrorFromService(error.response, t),
            autoClose: 5000,
          })
      );
  };

  const refExcelExport = useRef<ExcelExport>(null);

  const [state, setState] = useState<{
    progressIndicator: { isVisible: boolean; percentComplete: number };
    objectsState: { items: RealEstateForExcel[]; total: number };
    isError: boolean;
  }>({
    progressIndicator: {
      isVisible: false,
      percentComplete: 0,
    },
    objectsState: {
      items: [],
      total: 0,
    },
    isError: false,
  });

  const fetchObjects = async () => {
    const { objectsState, progressIndicator } = state;
    const { total, objects } = await search(
      clientID || 0,
      objectsState.items.length,
      30,
      sort,
      activeSearch
    );

    setState({
      ...state,
      objectsState: {
        items:
          objectsState.items && objectsState.total
            ? [
                ...objectsState.items,
                ...objects.map(
                  (object: RealEstate): RealEstateForExcel => ({
                    ...object,
                    street: `${object.street}/${object.houseNumber}`,
                    phone: `+${object.phone}`,
                    isRentedForExcel: object.isRented
                      ? t("recadm.search.isRented.yes")
                      : t("recadm.search.isRented.no"),
                  })
                ),
              ]
            : objects.map(
                (object: RealEstate): RealEstateForExcel => ({
                  ...object,
                  street: `${object.street}/${object.houseNumber}`,
                  phone: `+${object.phone}`,
                  isRentedForExcel: object.isRented
                    ? t("recadm.search.isRented.yes")
                    : t("recadm.search.isRented.no"),
                })
              ),
        total,
      },
      progressIndicator: {
        isVisible: true,
        percentComplete:
          progressIndicator.percentComplete + objects.length / total,
      },
    });
  };

  // this useEffect is responsible for fetching objects until the length of the objects
  // array is equal to total (end has been reached) and updating the progress indicator
  // after each request is completed
  useEffect(() => {
    const { isError, objectsState } = state;

    if (isError) return;
    if (objectsState.items.length === objectsState.total) {
      if (refExcelExport.current && objectsState.items.length > 0) {
        (refExcelExport.current as any).save(objectsState.items);
        setState({
          ...state,
          progressIndicator: {
            isVisible: false,
            percentComplete: 0,
          },
          objectsState: {
            items: [],
            total: 0,
          },
          isError: false,
        });
      }
      return;
    }
    (async () => {
      try {
        await fetchObjects();
      } catch {
        // This is a fix for a case when user simultaneously
        // scrolls down the objects list and downloads report
        // See claims bug #8926
        setTimeout(async () => {
          try {
            await fetchObjects();
          } catch (err) {
            setState({
              ...state,
              objectsState: {
                items: [],
                total: 0,
              },
              progressIndicator: {
                isVisible: false,
                percentComplete: 0,
              },
              isError: true,
            });
            //onError(err);
          }
        }, 500);
      }
    })();
  }, [activeSearch, sort, columns, clientID, state, t]);

  // callback that should be called by the consumer of this hook
  // this callback makes the first request and shows the progress indicator
  const exportExcel = useCallback(() => {
    (async () => {
      await fetchObjects();
    })();
  }, [setState, t, activeSearch, sort, columns, state, clientID]);

  return (
    <>
      <CommandBar
        items={[
          {
            key: "downloadExcel",
            text: "Excel",
            iconProps: {
              iconName: "ExcelDocument",
              styles: { root: { color: "#008000" } },
            },
            onClick: () => exportExcel(),
          },
          {
            key: "changeClient",
            name: `${clientName || "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}`,
            title: t("pol.search.changeClient.title"),
            iconProps: {
              iconName: "ProfileSearch",
            },
            onClick: openClientModal,
          },
          {
            key: "search",
            onRender: () => (
              <Stack verticalAlign="center" style={{ padding: 0 }}>
                <SearchBox
                  styles={{
                    root: {
                      height: 30,
                      width: 170,
                      marginLeft: 10,
                      border: "none",
                      backgroundColor: theme.palette.themeLighterAlt,
                    },
                  }}
                  placeholder={t("header.Search")}
                  onSearch={performSearch}
                  value={searchQuery}
                  onChange={(_event: any, newValue: any) => {
                    setSearch(newValue);
                  }}
                  onClear={setSearch}
                  disableAnimation
                />
              </Stack>
            ),
          },
        ]}
        farItems={[
          {
            key: "upload",
            name: t("rec.upload.text"),
            iconProps: {
              iconName: "Upload",
            },
            onClick: () => inputRef.current.click(),
          },
        ]}
      />
      <input
        type="file"
        hidden
        ref={inputRef}
        onChange={onChange}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <ExportExcel ref={refExcelExport} />
    </>
  );
};
