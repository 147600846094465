import React from "react";
import { ActionButton } from "office-ui-fabric-react";
import { TFunction } from "i18next";
import { ModelError } from "../api/obj-api-model/ModalError";

const messageBar = (message: ModelError, t: TFunction) => {
  let textToCopy =
    t(message.messageCode) +
    "\n Error code: " +
    message.errorCode +
    "\n CorrelationID: " +
    message.correlationID;

  return (
    <>
      <p
        key={message.errorCode}
        style={
          t(message.messageCode).length > 42
            ? { margin: 0, paddingBottom: 5 }
            : { margin: 0 }
        }
      >
        {message.messageVariables
          ? t(message.messageCode).replace("{0}", message.messageVariables[0])
          : t(message.messageCode)}
        <br />(<strong>{t("rec.copyToClipboard.text")}</strong>
        <ActionButton
          iconProps={{ iconName: "Copy" }}
          style={{ height: 17 }}
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard
              .writeText(textToCopy)
              .then(() => {})
              .catch(() => {});
          }}
        ></ActionButton>
        )
        {message.correlationID && (
          <span>
            <br />
            <strong>Correlation ID:</strong> {message.correlationID}
          </span>
        )}
        {message.errorCode && (
          <span>
            <br />
            <strong>Error Code:</strong> {message.errorCode}
          </span>
        )}
      </p>
    </>
  );
};

export const parseErrorFromService = (response: any, t: TFunction): any => {
  const data = response.data;
  if (data.length > 0) {
    return (
      <>
        {data.map((messagePart: ModelError, index: number) => {
          return (
            <div key={`${index}`}>
              {messageBar(messagePart, t)}
              {data.length !== index + 1 && <br />}
            </div>
          );
        })}
      </>
    );
  } else if (data) {
    return messageBar(data as any, t);
  }

  return t("rec.error.title");
};
