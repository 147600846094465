import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { UserDataLoader } from './api/UserDataLoader';

const defaultLanguage = 'en-GB';

// creating a language detection plugin
// http://i18next.com/docs/ownplugin/#languagedetector
const languageDetector = {
  type: ('languageDetector' as any), // Exact type is 'backend' | 'logger' | 'languageDetector' | 'postProcessor' | 'i18nFormat' | '3rdParty'
  async: true, // flags below detection to be async
  detect: (callback: Function) => {
    return UserDataLoader.getUserData()
        .then(userData => callback(userData.preferredLanguage || defaultLanguage))
        .catch(error => callback(defaultLanguage));
  },
  init: () => {},
  cacheUserLanguage: () => {}
};

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLanguage,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    whitelist: ['en-GB', 'de-AT', 'bg-BG', 'cs-CZ', 'et-EE', 'hr-HR', 'hu-HU', 'lt-LT', 'pl-PL', 'ro-RO', 'ru-RU', 'sr-Latn-RS', 'sk-SK', 'sl-SI', 'tr-TR', 'uk-UA'],
    backend: {
      loadPath: `${process.env.REACT_APP_PHRASE_BASE_URL}/{{lng}}.json`
    }
  });

  export default i18n;