import * as React from "react";
import "./List.scss";
import { AppContext } from "../../contexts/appContext";
import { WithTranslation, withTranslation } from "react-i18next";
import { ActionBar } from "../../components/ActionBar/ActionBar";
import ClientsSearchModal from "../../components/ClientsSearch/ClientsSearchModal";
import { ObjectsTable } from "../../components/ObjectsSearch/ObjectsTable";
import { SortCriteria } from "../../api/ObjectsApi";

export interface IListState {
  clientName?: string;
  clientID?: number;
  showClientModal: boolean;
  search?: string;
  sort: SortCriteria;
}

class List extends React.PureComponent<WithTranslation, IListState> {
  constructor(props: WithTranslation) {
    super(props);
    this.state = {
      showClientModal: true,
      sort: { key: "", dir: "desc" },
    };
  }

  public render(): JSX.Element {
    return (
      <AppContext.Consumer>
        {(appState: any) => (
          <>
            <ActionBar
              performSearch={appState.searchOnSearch}
              setSearch={appState.searchOnChange}
              searchQuery={appState.search}
              clientID={this.state.clientID}
              clientName={this.state.clientName}
              openClientModal={() => this.setState({ showClientModal: true })}
              sort={this.state.sort}
            />
            <ObjectsTable
              clientID={this.state.clientID}
              sort={this.state.sort}
              setSort={(sort: SortCriteria) => this.setState({ sort })}
            />
            <ClientsSearchModal
              isOpen={this.state.showClientModal}
              setModalVisible={(visible: boolean) =>
                this.setState({ showClientModal: visible })
              }
              assignList={(clientID: number) => {
                this.setState({ showClientModal: false, clientID });
                appState.searchOnSearch("");
                appState.searchOnChange("");
              }}
              setListName={(name: string) =>
                this.setState({ clientName: name })
              }
            />
          </>
        )}
      </AppContext.Consumer>
    );
  }
}

export default withTranslation()(List);
