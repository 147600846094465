import * as React from "react";
import "./App.scss";
import Logo from "../src/assets/GrECo_Logo_small.jpg";

import { BrowserRouter as Router, Route } from "react-router-dom";
// --- Configurations
import { headerConfig } from "./config/headerConfig";
// --- API
import { logout, getUserPhotoSmall } from "./api/GraphService";
// --- Greco Components
import { GrecoSpinner } from "@greco/services";
// --- Routes
import List from "./pages/List/List";
// --- Application context
import { AppContext } from "./contexts/appContext";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { Suspense } from "react";
import { UserDataLoader } from "./api/UserDataLoader";
import AppHeader from "./components/Header/AppHeader";
import { getTheme, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyles, ThemeProvider } from "@greco/components";

interface NotifyProps {
  type: "success" | "warning" | "error";
  title: string;
  content: string;
  autoClose?: number | false;
  closeOnClick?: boolean;
}

interface IState {
  headerConfig: any;
  panelIsOpen: boolean;
  user: any;
  error: any;
  loading: boolean;
  search: string;
  searchQuery: string;
  searchOnChange: any;
  searchOnSearch: any;
  appInsights: ApplicationInsights;
  toast: {
    notify: (value: NotifyProps) => void;
  };
}

export class App extends React.PureComponent<{}, IState> {
  constructor(props: {}) {
    super(props);

    // Telemetry setup
    // For more details see https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-react-js
    var reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env
          .REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY as string,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: createBrowserHistory({ basename: "" }),
          },
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    this.state = {
      headerConfig: headerConfig,
      panelIsOpen: false,
      user: {},
      error: "",
      loading: false,
      search: "",
      searchQuery: "",
      searchOnChange: this.searchOnChange.bind(this),
      searchOnSearch: this.searchOnSearch.bind(this),
      appInsights: appInsights,
      toast: { notify: this.notify },
    };

    this.getUserData();
  }

  Msg = (values: { values: NotifyProps }) => (
    <div className="notif-wrapper">
      {values.values.type === "success" ? (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          styles={{
            root: {
              height: "100%",
              width: "100%",
            },
          }}
        >
          <h2 className="success-title">{values.values.title}</h2>
          <p className="success-content">{values.values.content}</p>
        </MessageBar>
      ) : null}
      {values.values.type === "error" ? (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          styles={{
            root: {
              height: "100%",
              width: "100%",
              color: getTheme().palette.neutralDark,
            },
          }}
        >
          <h2 className="error-title">{values.values.title}</h2>
          <div
            className="error-content"
            style={{ whiteSpace: "initial", margin: "10px 0" }}
          >
            <p style={{ whiteSpace: "pre-wrap" }}>{values.values.content}</p>
          </div>
        </MessageBar>
      ) : null}
      {values.values.type === "warning" ? (
        <MessageBar
          isMultiline={false}
          styles={{
            root: {
              height: "100%",
              width: "100%",
            },
          }}
        >
          <h2 className="warning-title">{values.values.title}</h2>
          <div className="warning-content" style={{ margin: "10px 0" }}>
            {values.values.content}
          </div>
        </MessageBar>
      ) : null}
    </div>
  );

  notify = (value: NotifyProps) => {
    toast(<this.Msg values={value} />, {
      className: "toast-default-background",
      progressClassName: "toast-blue-loading",
      closeOnClick: value.closeOnClick || false,
      autoClose: value.autoClose === false ? false : value.autoClose || 3000,
    });
  };

  public render(): JSX.Element {
    return (
      <ThemeProvider isDarkMode={false}>
        <AppContext.Provider value={this.state}>
          <Suspense fallback={<GrecoSpinner />}>
            <div>
              {this.state.loading && <GrecoSpinner />}

              <AppHeader
                user={this.state.user}
                headerConfig={this.state.headerConfig}
                logo={Logo}
                logout={logout}
                panelStatus={this.panelStatus.bind(this)}
                searchOnChange={this.state.searchOnChange.bind(this)}
                searchOnSearch={this.state.searchOnSearch.bind(this)}
              />
              <ToastContainer />
              <div
                className={
                  this.state.panelIsOpen
                    ? "app-wrapper app-wrapper--panelIsOpen"
                    : "app-wrapper"
                }
              >
                <Router>
                  <Route exact path="/" component={List} />
                </Router>
              </div>
            </div>
          </Suspense>
        </AppContext.Provider>
        <GlobalStyles />
      </ThemeProvider>
    );
  }

  private getUserData() {
    UserDataLoader.getUserData().then((userData) => {
      this.setState({ user: userData, loading: false });
    });

    getUserPhotoSmall().then((imageUrl: string) => {
      this.setState({
        user: { ...this.state.user, photoSmall: imageUrl },
      });
    });
  }

  private panelStatus(status: boolean) {
    this.setState({ panelIsOpen: status });
  }

  private searchOnChange(value: string) {
    this.setState({ search: value });
  }

  private searchOnSearch(value: string) {
    this.setState({ searchQuery: value });
  }
}
