import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { GrecoHeader } from "@greco/components";

interface IAppHeaderProps extends WithTranslation {
  headerConfig?: any;
  user?: any;
  panelStatus?: Function;
  tooltipStatusChange?: any;
  logo?: any;
  logout?: any;
  searchOnChange?: any;
  searchOnSearch?: any;
  search?: string;
  darkMode?: any;
}

class AppHeader extends React.PureComponent<IAppHeaderProps, {}> {
  public render(): JSX.Element {
    return (
      <GrecoHeader
        headerConfig={this.props.headerConfig}
        user={this.props.user}
        panelStatus={this.props.panelStatus}
        tooltipStatusChange={this.props.tooltipStatusChange}
        logo={this.props.logo}
        logout={this.props.logout}
        searchOnChange={this.props.searchOnChange}
        searchOnSearch={this.props.searchOnSearch}
        search={this.props.search}
        darkMode={this.props.darkMode}
        headerStrings={{
          notificationsPanelStrings: {
            panelTitle: this.props.t("header.Notifications"),
            noNotificationsTitle: this.props.t("header.NoNotificationsTitle"),
            noNotificationsSubtitle: this.props.t(
              "header.NoNotificationsSubtitle"
            ),
          },
          helpPanelStrings: {
            panelTitle: this.props.t("header.Help"),
          },
          settingsPanelStrings: {
            panelTitle: this.props.t("header.Settings"),
          },
          userSettingsPanelStrings: {
            panelTitle: this.props.t("header.UserSettings"),
            myOfficeProfileLinkText: this.props.t("header.MyOfficeProfile"),
            myAccountLinkText: this.props.t("header.UserSettings"),
            signOutLinkText: this.props.t("header.SignOut"),
          },
          searchPlaceholder: this.props.t("header.Search"),
        }}
      />
    );
  }
}

export default withTranslation()(AppHeader);
