import React, { useEffect, useRef } from "react";
import {
  getTheme,
  DetailsList,
  SelectionMode,
  ActionButton,
  IDetailsHeaderProps,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
  IRenderFunction,
  IColumn,
  Spinner,
  SpinnerSize,
  Sticky,
  ScrollablePane,
} from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";

const clientColumns = [
  {
    field: "clientID",
    title: "",
    isSortable: false,
  },
  {
    field: "partnerName",
    title: "crm.search.clientName.label",
    minWidth: 260,
    maxWidth: 260,
    isSortable: true,
  },
  {
    field: "partnerNumber",
    title: "crm.search.partnerNumber.label",
    minWidth: 120,
    maxWidth: 120,
    isSortable: true,
  },
  {
    field: "address",
    title: "crm.search.address.label",
    minWidth: 230,
    maxWidth: 230,
    isSortable: false,
  },
  {
    field: "mainCostCenter",
    title: "crm.search.mainCostCenter.label",
    isSortable: false,
  },
  {
    field: "serviceStatusCode",
    title: "crm.search.serviceStatusCode.label",
    isSortable: false,
  },
];

interface Props {
  list: any;
  modalCtx: string;
  getLists: (shouldClear: boolean, searchPattern?: string) => void;
  sortColumn: (col: any) => void;
  sort: any;
  assignList?: (id: number) => void;
  setListName?: (name: string) => void;
  clientID?: number;
}

const ListTable = ({
  list,
  modalCtx,
  getLists,
  sortColumn,
  sort,
  assignList,
  setListName,
  clientID,
}: Props) => {
  const theme = getTheme();
  const { t } = useTranslation();
  const scrollablePaneRef = useRef<any>();

  useEffect(() => {
    let options = {
      root: scrollablePaneRef.current && scrollablePaneRef.current.root,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const target = document.querySelector(".obeserve-target-list");

    let observer = new IntersectionObserver((entries: any) => {
      const first: any = entries[0];
      if (first.isIntersecting) {
        if (list.items.length < list.total) {
          getLists(false);
        }
      }
    }, options);

    if (target) {
      observer.observe(target);
    }
    return () => {
      observer.disconnect();
    };
  });

  const centerElements: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const noContentText: any = {
    textAlign: "center",
    fontSize: "1.4rem",
    fontWeight: 600,
    color: theme.palette.neutralSecondary,
  };

  const scrollToTop = (scrolElement: React.RefObject<any>, bottom?: any) => {
    scrolElement.current._root.current.childNodes[1].scrollTo(0, 0);
    if (bottom) {
      bottom.current = 40;
    }
  };

  function onRenderDetailsHeader(
    props?: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ): JSX.Element {
    return (
      <Sticky isScrollSynced={true}>
        {defaultRender!({
          ...props!,
          styles: {
            root: {
              paddingTop: 0,
              borderTop: `1px solid ${theme.palette.neutralLight}`,
            },
          },
        })}
      </Sticky>
    );
  }

  const onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      customStyles.root = {
        color: theme.palette.black,
        fontWeight: "600",
        cursor: "default",
        backgroundColor:
          props.item.clientID === clientID
            ? theme.palette.themeLighter
            : props!.itemIndex % 2 === 0
            ? theme.palette.themeLighterAlt
            : theme.palette.white,
        selectors: {
          "&:hover": {
            backgroundColor: theme.palette.themeLighter,
          },
        },
      };

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  function getColumns() {
    let newColumns: IColumn[] = clientColumns.map((item: any) => {
      let fabricColumn: IColumn = {
        key: item.field,
        fieldName: item.field,
        name: t(`${item.title}`),
        minWidth: item.minWidth || 100,
        maxWidth: item.maxWidth || 150,
        isSorted: sort && sort.field === item.field,
        isSortedDescending: !sort
          ? false
          : sort.field === item.field
          ? sort.dir === "desc"
          : true,
      };

      if (fabricColumn.fieldName === `${modalCtx}ID`) {
        fabricColumn.minWidth = 20;
        fabricColumn.maxWidth = 20;
        fabricColumn.name = "";
        fabricColumn.onRender = (item: any) => {
          return (
            <ActionButton
              style={{
                verticalAlign: "middle",
                maxHeight: "16px",
                padding: 0,
              }}
              onClick={() => {
                assignList && assignList(item[`${modalCtx}ID`]);
                if (item.partnerName && setListName) {
                  setListName(item.partnerName);
                } else if (setListName) {
                  setListName(item.listName);
                }
              }}
              title={t("crm.search.button.edit.title")}
              iconProps={{ iconName: "MoveToFolder" }}
            />
          );
        };
      }

      if (item.isSortable) {
        fabricColumn.onColumnClick = () => {
          sortColumn(item);
          scrollToTop(scrollablePaneRef);
        };
        fabricColumn.iconName = !fabricColumn.isSorted ? "Sort" : "";
        fabricColumn.iconClassName = "sort-icon";
        // @ts-ignore
        fabricColumn.styles = { root: { cursor: "pointer" } };
      }

      if (item.field === "address") {
        fabricColumn.onRender = (item, index) => (
          <p style={{ whiteSpace: "initial", width: "100%" }} key={index}>
            {item.address}
          </p>
        );
      }

      return fabricColumn;
    });

    return newColumns;
  }

  function getItems() {
    const items: any =
      list &&
      list.items.map((item: any) => {
        if (!item) {
          return null;
        }
        return {
          key: parseInt(`${item.partnerID}`),
          clientID: parseInt(`${item.partnerID}`),
          partnerName: item.partnerName,
          partnerNumber: item.partnerNumber,
          address: item.partnerAddress
            ? `${item.partnerAddress.street} ${item.partnerAddress.house}${
                item.partnerAddress.buildingFloorDoor
                  ? `/${item.partnerAddress.buildingFloorDoor}`
                  : ""
              }, ${item.partnerAddress.zipCode || ""} ${
                item.partnerAddress.city || ""
              }`
            : "",
          addressComposite: item.addressComposite,
          mainCostCenter: t(item.costCenterCodeName),
          isOrganization: item.isOrganization,
          serviceStatusCode:
            item.serviceStatusCode && t(`${item.serviceStatusCode}`),
        };
      });

    return items;
  }

  return (
    <>
      {list && list.items.length > 0 ? (
        <ScrollablePane
          componentRef={scrollablePaneRef}
          style={{ position: "absolute", top: 66 }}
          styles={{
            stickyBelowItems: { display: "none" },
          }}
        >
          <DetailsList
            columns={getColumns()}
            items={getItems()}
            onRenderDetailsHeader={onRenderDetailsHeader}
            onRenderRow={onRenderRow}
            useReducedRowRenderer={false}
            selectionMode={SelectionMode.none}
          />
          <div
            className="obeserve-target-list"
            style={{
              height: "10px",
              width: "100%",
              position: "relative",
              bottom: `60%`,
            }}
          ></div>
        </ScrollablePane>
      ) : !list.content ? (
        <div style={{ ...noContentText, ...centerElements }}>
          <span>{t("crm.search.noRecords.text")}</span>
        </div>
      ) : (
        <Spinner
          size={SpinnerSize.large}
          styles={{
            root: centerElements,
          }}
        />
      )}
    </>
  );
};

export default ListTable;
