import axios from "axios";
import { MSGraphAuth } from "./GraphService";
import { MSGraphParams } from "../config/MSGraphConfig";
import { RealEstate } from "./obj-api-model/RealEstate";
import { AuthenticationResult } from "@azure/msal-browser";

export interface SortCriteria {
  key: string;
  dir: "desc" | "asc";
}

export const search = async (
  clientID: number,
  skip: number,
  top: number,
  sort: SortCriteria,
  search?: string
): Promise<{ objects: RealEstate[]; total: number }> => {
  const authResponse = await MSGraphAuth.getAccessToken(MSGraphParams);

  if (!authResponse) throw new Error("authorization error");

  const res = await axios.get(
    `${
      process.env.REACT_APP_BASE_URL
    }/search/${clientID}?$form=json&$count=true$top=${top}&$skip=${skip}${
      sort.key ? `&$orderby=${sort.key} ${sort.dir}` : ""
    }${search ? `&search=${search}` : ""}`,
    {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${authResponse.accessToken}`,
      },
    }
  );

  const json = res.data;
  const total = json.count;
  const objectResults = json.results;

  const objects: RealEstate[] = objectResults.map(
    (objRes: any) => objRes.document
  );

  return { objects, total };
};

export const uploadList = (clientID: number, file: any) =>
  new Promise<void>((resolve, reject) => {
    MSGraphAuth.getAccessToken(MSGraphParams).then(
      (authResponse: AuthenticationResult | void) => {
        if (!authResponse) throw new Error("authorization error");

        const formData = new FormData();
        formData.append("REs", file);

        axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/importrealestates/${clientID}`,
          data: formData,
          headers: {
            Authorization: `Bearer ${authResponse.accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  });
