import { DetailsListColumn } from "@greco/components";

export const columns: DetailsListColumn[] = [
  {
    key: "policyNumberInsurer",
    label: "recadm.search.policyNumber.label",
    width: 200,
    isSortable: true,
  },
  {
    key: "name",
    label: "recadm.search.name.label",
    width: 200,
    isSortable: true,
  },
  {
    key: "shortName",
    label: "recadm.search.shortName.label",
    width: 150,
    isSortable: true,
  },
  {
    key: "street",
    label: "recadm.search.streetHouseNumber.label",
    width: 300,
    isSortable: true,
    align: "left",
  },
  {
    key: "zipCode",
    label: "recadm.search.zip.label",
    width: 100,
    isSortable: true,
    align: "left",
  },
  {
    key: "city",
    label: "recadm.search.city.label",
    width: 250,
    isSortable: true,
    align: "left",
  },
  {
    key: "phone",
    label: "recadm.search.phoneNumber.label",
    width: 100,
  },
  {
    key: "emailAddress",
    label: "recadm.search.emailAddress.label",
    width: 100,
  },
  {
    key: "bankName",
    label: "recadm.search.bankName.label",
    width: 100,
  },
  {
    key: "bicSWIFT",
    label: "recadm.search.bicSWIFT.label",
    width: 100,
  },
  {
    key: "ibanAccountNumber",
    label: "recadm.search.ibanAccountNumber.label",
    width: 100,
  },
  {
    key: "isRentedForExcel",
    label: "recadm.search.isRented.label",
    width: 100,
  },
];
