import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { FluentCustomizations } from "@uifabric/fluent-theme";
import { Customizer, mergeStyles } from "office-ui-fabric-react";
import * as serviceWorker from "./serviceWorker";
import { initializeIcons } from "@uifabric/icons";
import "./index.scss";
import "../node_modules/@greco/services/dist/lib/main.css";
import "./i18n";

// -- Auth
import { MSGraphAuth } from "./api/GraphService";
import { AccountInfo } from "@azure/msal-browser";

// Inject some global styles
mergeStyles({
  selectors: {
    ":global(body), :global(html), :global(#root)": {
      margin: 0,
      padding: 0,
      height: "100vh",
    },
  },
});

const startApplication = () => {
  initializeIcons();
  ReactDOM.render(
    <Customizer {...FluentCustomizations}>
      <App />
    </Customizer>,
    document.getElementById("root")
  );
};

MSGraphAuth.accountObj
  .then((accountInfo: AccountInfo | null) => {
    if (accountInfo) {
      startApplication();
    } else {
      MSGraphAuth.login();
    }
  })
  .catch((error: any) => {
    if (error === "There isn't any account detected.") {
      MSGraphAuth.login();
    } else {
      // TODO log error
    }
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
