import axios from "axios";
import { MSGraphAuth } from "./GraphService";
import { Client } from "./crm-api-model/Client";
import { MSGraphParams } from "../config/MSGraphConfig";

const url = process.env.REACT_APP_BASE_URL;
/**
 * Get
 */

let callClient: any;

export const getSearchList = async (
  searchRoute: string,
  skip: number,
  sort: { field: string; dir: "asc" | "desc" },
  searchPattern?: string,
  top?: number
): Promise<{ clients: Client[]; total: number }> => {
  const authResponse = await MSGraphAuth.getAccessToken(MSGraphParams);
  if (!authResponse) {
    return { clients: [], total: 0 };
  }

  callClient && callClient.cancel("Only one request allowed at a time.");
  callClient = axios.CancelToken.source();

  let _url = "";
  _url += url;
  _url += searchRoute;
  _url += "?$form=json";
  _url += "&$count=true";
  _url += `&$top=${top || 20}`;
  _url += `&$skip=${skip}`;
  _url += `&$orderby=${sort.field} ${sort.dir}`;
  if (searchPattern) {
    _url += `&search=${searchPattern}`;
  }

  const res = await axios.get(_url, {
    cancelToken: callClient.token,
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + authResponse.accessToken,
    },
  });

  const json = res.data;
  const total = res.status === 204 ? 0 : json.count;
  const listResults: any[] =
    res.status === 204 ? [] : json.results.map((item: any) => item.document);

  return { clients: listResults, total };
};
