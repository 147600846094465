import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IconButton,
  SearchBox,
} from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/styling";
import ListTable from "./table/ListTable";
import { useTranslation } from "react-i18next";
import * as CrmAPI from "../../api/CrmApi";

interface Props {
  isOpen: boolean;
  setModalVisible: (open: boolean) => void;
  assignList?: (id: number) => void;
  setListName?: (name: string) => void;
  clientID?: number;
}

type SortState = {
  field: string;
  dir: "asc" | "desc";
};

const ClientsSearchModal = ({
  isOpen,
  setModalVisible,
  assignList,
  setListName,
  clientID,
}: Props) => {
  const { t } = useTranslation();
  const theme = getTheme();

  const refSkipList = useRef(0);

  const [searchList, setSearchList] = useState<string | undefined>();
  const [sortLists, setSortLists] = useState<SortState>({
    field: "partnerName",
    dir: "desc",
  });

  const [modalCtx] = useState("client");

  const [list, setList] = useState<any>({
    items: [],
    total: 0,
    content: true,
  });

  useEffect(() => {
    isOpen && getLists(true);
  }, [isOpen, sortLists]);

  useEffect(() => {
    searchList !== undefined && searchList === "" && getLists(true, searchList);
  }, [searchList]);

  const getLists = async (
    shouldClear: boolean,
    search?: string | undefined
  ) => {
    const skip = shouldClear ? 0 : refSkipList.current || 0;
    try {
      const { clients: listResults, total } = await CrmAPI.getSearchList(
        "/searchclients",
        skip,
        sortLists,
        search
      );

      let listForState: any[] = [...listResults];

      if (!shouldClear) {
        listForState = list.items.concat(listResults);
      }
      if (listForState.length === 0) {
        setList({
          items: [],
          total: 0,
          content: false,
        });
      } else {
        setList({
          items: listForState,
          total,
          content: true,
        });
      }
      refSkipList.current = skip + listResults.length;
    } catch (err) {
      if (err.isAxiosError) {
        setList({
          items: [],
          total: 0,
          content: false,
        });
      }
    }
  };

  const contentStyles = mergeStyleSets({
    container: {
      width: 1100,
      height: 600,
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        fontSize: FontSizes.xLarge,
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
      },
    ],
    body: {
      flex: "4 4 auto",
      overflowY: "hidden",
      selectors: {
        p: {
          margin: "14px 0",
        },
        "p:first-child": {
          marginTop: 0,
        },
        "p:last-child": {
          marginBottom: 0,
        },
      },
    },
  });

  const iconButtonStyles = mergeStyleSets({
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  });

  const sortColumnLists = (col: any) => {
    if (col.field === sortLists.field) {
      setSortLists({
        field: sortLists.field,
        dir: sortLists.dir === "asc" ? "desc" : "asc",
      });
    } else {
      // if the columns is already sorted, reset sort to default
      if (sortLists.field === col.field) {
        if (sortLists.dir === "desc") {
          setSortLists({
            field: sortLists.field,
            dir: "desc",
          });
        } else {
          setSortLists({
            field: col.field,
            dir: "desc",
          });
        }
      } else {
        setSortLists({
          field: col.field,
          dir: "asc",
        });
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => setModalVisible(false)}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span>{t("crm.search.title")}</span>
        <SearchBox
          placeholder={t("crm.search.input.placeholder")}
          styles={{
            root: {
              height: 30,
              width: 170,
              marginLeft: 10,
              border: "none",
              backgroundColor: theme.palette.themeLighterAlt,
            },
          }}
          onSearch={() => {
            setList({ items: [], total: 0, content: true });
            if (!searchList || searchList.length === 0) {
              getLists(true, "");
            } else {
              getLists(true, searchList);
            }
          }}
          onChange={(e: any, newValue?: any) => {
            setSearchList(newValue);
            if (!newValue || newValue.length === 0) {
              getLists(true, "");
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setList({ items: [], total: 0, content: true });
              if (!searchList || searchList.length === 0) {
                getLists(true, "");
              } else {
                getLists(true, searchList);
              }
            }
          }}
          id="listSearchInput"
        />
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: "Cancel" }}
          onClick={() => setModalVisible(false)}
          id="clistSearchClose"
        />
      </div>
      <div className={contentStyles.body}>
        <ListTable
          list={list}
          modalCtx={modalCtx}
          getLists={getLists}
          sortColumn={sortColumnLists}
          sort={sortLists}
          assignList={assignList}
          setListName={setListName}
          clientID={clientID}
        />
      </div>
    </Modal>
  );
};

export default ClientsSearchModal;
